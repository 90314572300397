<template>
  <div class="page">
    <div class="nav-bar">
      <div class="left" @click="goBack">
        <van-icon name="arrow-left" size="24" color="#1D1D2C" />
      </div>
      <div class="center">记录</div>
      <div class="right"></div>
    </div>
    <div class="nav-bar-block" style="height: 96px"></div>
    <div class="record-list" v-if="listData.length">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        @load="getData"
        finished-text=""
      >
        <div class="item" v-for="item in listData" :key="item.transactionTime">
          <div class="row-1">
            <div class="lt">
              <img src="@assets/wx.png" alt="" v-if="item.payType == '2'" />
              <img src="@assets/alipay.png" alt="" v-if="item.payType == '1'" />
              <div>{{ item.equityTitle }}</div>
            </div>
            <div class="rt">{{ item.transactionAmount }}元</div>
          </div>
          <div class="row-2">{{ item.transactionTime }}</div>
        </div>
      </van-list>
    </div>
    <div v-else>
      <img style="width:200px;margin:100px auto 20px;display:block" src="@assets/none.png" alt="">
      <div style="text-align:center;font-size:16px">暂无数据，敬请期待</div>
    </div>
  </div>
</template>

<script>
import { getRecordList } from './api.js';
export default {
  data() {
    return {
      pageData: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      finished: false,
      listData: [],
      loading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      getRecordList(this.pageData).then((res) => {
        if (res.code === 1) {
          this.loading = false;
          this.listData = this.listData.concat(res.data);
          if (res.data.length < this.pageData.pageSize) {
            this.finished = true;
            return;
          }
          this.pageData.pageNum++;

        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  background: #f5f7f9;
  min-height: 100vh;
}
.nav-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  height: 84px;
  padding-top: 50px;
  display: flex;
  justify-content: space-between;
  background: #fff;
  .right{
    width: 16px;
  }
}
.record-list {
  margin-top: 8px;
  height: calc(100vh - 90px);
  overflow: scroll;
  .item {
    box-sizing: border-box;
    height: 95px;
    background: #fff;
    border-bottom: 1px solid #f5f7f9;
    padding: 20px 16px;
    .row-1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 700;
      font-size: 16px;
      color: #1d1d2c;
      .lt {
        display: flex;
        align-items: center;
        img {
          width: 32px;
          height: 32px;
          margin-right: 10px;
        }
      }
    }
    .row-2 {
      font-size: 11px;
      color: #b3b4ba;
      margin-top: 12px;
    }
  }
}
</style>